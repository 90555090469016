import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useLoginModalShow } from '../../../../store'
import { useAuth } from '../../../Auth/context/AuthContext'
import hallApi from '../../api/hallApi'
import { useSocket } from '../../hooks/socketHook'
import style from '../Hall/css/BetArea.module.css'
import BetArea from './elements/BetArea'
import HallPicker from './elements/HallPicker'
import History from './elements/History'
import Party from './elements/Party'
import UserReport from './elements/UserReport'
import BetAreaTabs from './elements/BetAreaTabs'

function SingleHall({ hall }) {
    const hallId = hall.id
    const videoSource = hall.videoSource
    const { user } = useAuth()
    const { setLoginModalShow } = useLoginModalShow()
    const [data, setData] = useState({})
    const [liveStatus, setLiveStatus] = useState(false)
    const [rateWala, setRateWala] = useState(0)
    const [rateMeron, setRateMeron] = useState(0)
    const [betStatus, setBetStatus] = useState(0)
    const [fightId, setFightId] = useState(null)
    const [fightNumber, setFightNumber] = useState(null)
    const [result, setResult] = useState(null)
    const [end_bet_at, setEnd_bet_at] = useState(null)
    const [elapsedTime, setElapsedTime] = useState(0)
    const [startedAt, setStartedAt] = useState(null)
    const [intervalId, setIntervalId] = useState(null)

    useEffect(() => {
        const fetchResult = async () => {
            try {
                const datalastfight = await hallApi.lastFight(hallId);
                setResult(datalastfight.result);
                setEnd_bet_at(datalastfight.end_bet_at);
                if (datalastfight.started_at) {
                    setStartedAt(new Date(datalastfight.started_at));
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (hallId) {
            fetchResult();
        }
    }, [hallId]);

    useSocket({
        type: 'RATE_CHANGE',
        callBack: (payload) => {
            switch (payload.party) {
                case 'wala':
                    setRateWala(payload.rate)
                    setRateMeron(payload.rate2)
                    break
                case 'meron':
                    setRateMeron(payload.rate)
                    setRateWala(payload.rate2)
                    break
                default:
                    break
            }
        }
    })

    useSocket({
        type: 'STATUS_CHANGE',
        callBack: (payload) => {
            switch (payload.status) {
                case 1:
                    const data = payload.data.fight;
                    updateFightData({
                        rateWala: data.rateWala,
                        rateMeron: data.rateMeron,
                        status: data.status,
                        currentFight: data.id,
                        currentFightNumber: data.fight_number,
                        startedAt: data.started_at
                    });
                    setResult(null);
                    setEnd_bet_at(null);
                    startElapsedTime(new Date(data.started_at));
                    break;
                case 2:
                    setResult(payload.data.result);
                    setEnd_bet_at(payload.data.end_bet_at);
                    break;
                case 0:
                    setResult(payload.data.result);                    
                    setBetStatus(payload.data.status);
                    setEnd_bet_at(payload.data.end_bet_at);
                    break;
                default:
                    break;
            }
        }
    });

    useEffect(() => {
        fetchData(hallId)
    }, [hallId])

    const fetchData = async (hallId) => {
        if (hallId !== undefined) {
            try {
                const data = await hallApi.detail(hallId)
                setLiveStatus(data.hall.live === 1)
                updateFightData(data)
                return data
            } catch (error) {
                console.error('Failed to fetch hall detail:', error)
            }
        }
    }

    const updateFightData = (data) => {
        setRateMeron(data.rateMeron)
        setRateWala(data.rateWala)
        setBetStatus(data.status)
        setFightId(data.currentFight)
        setFightNumber(data.currentFightNumber)
        if (data.started_at) {
            setStartedAt(new Date(data.started_at))
        }
    }

    const startElapsedTime = (startTime) => {
        setStartedAt(startTime);
        const interval = setInterval(() => {
            const now = Date.now();
            const elapsed = now - startTime.getTime();
            setElapsedTime(elapsed);
        }, 10);
        setIntervalId(interval);
    };

    useEffect(() => {
        let interval = null;
    
        if (result === null && end_bet_at === null) {
            if (startedAt) {
                interval = setInterval(() => {
                    const now = Date.now();
                    const elapsed = now - startedAt.getTime();
                    setElapsedTime(elapsed);
                }, 10);
            }
        } else {
            clearInterval(intervalId);
            setElapsedTime(0);
        }
        return () => clearInterval(interval);
    }, [result, startedAt]);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000)
        const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0')
        const seconds = (totalSeconds % 60).toString().padStart(2, '0')
        const millis = (milliseconds % 1000).toString().padStart(3, '0')
        return `${minutes}:${seconds}:${millis}`
    }


    const renderOverlayText = () => {
        if (result === null && end_bet_at === null) {
            return (
                <div>
                    <span>Currently fighting </span>
                    <span className="timerOverlay">({formatTime(elapsedTime)})</span>
                </div>
            );
        } else if (result === 'cancel') {
            return 'Match canceled';
        } else if (result !== null && betStatus === 0) {
            return (
                <span>
                    The match is over 
                    <span style={{ color: 'gold' }}> ({result} win)</span>
                    
                </span>
            );
        } else {
            return 'The match is over';
        }
    };
    
    if (!hallId) {
        return <>Loading...</>
    }

    return (
        <CContainer xl className={style.hallContainer}>
            <CRow>
            <CCol sm={12} lg={8} className='px-0'>

            <HallPicker hallId={hall.key} type={hall.type}/>
                {/* <HallPlayer source={videoSource} height='480' />: */}
                <div className="content">
                        <div className="overlay">
                            {renderOverlayText()}
                        </div>
                        <div className="fightNumOverlay">
                            <div className="fightNumber">
                                #{fightNumber}
                            </div>
                        </div>
                    <div className='fightStatusOverlay'>
                        
                    </div>
                <video autoPlay controls muted style={{margin: 'auto', width: '100%', border:'10px solid #fff'}}>
                <source src="/Video.mp4" type="video/mp4" />
                </video>
                </div>
                {/* <Party /> */}
            </CCol>
            <CCol sm={12} lg={4} className='px-0'>
                <BetAreaTabs hallId={hallId} fightId={fightId} fightNumber={fightNumber} rateWala={rateWala} rateMeron={rateMeron} />
                {/* {user!=null?<BetArea hallId={hallId} fightId={fightId} fightNumber={fightNumber} rateWala={rateWala} rateMeron={rateMeron} />:<CButton color='light' shape='rounded-pill' onClick={()=>setLoginModalShow(true)} style={{marginTop: '30%', width: '100%'}}>Login / Register to start betting</CButton>} */}
                
            </CCol>
            </CRow>
            <CRow>
            <CCol sm={12} lg={8} className='px-0'>
                    <History hallId={hallId} />
                </CCol>
                {user!=null?<><CCol sm={12} lg={4} className='px-0'>
                    {/* <UserReport fightId={fightId} fightNumber={fightNumber} /> */}
                </CCol></>:''}
            </CRow>

        </CContainer>
    )
}

export default SingleHall
