import { create } from "zustand";

const useSidebarStore = create(set => ({
    sidebarShow : false,
    setSidebarShow: value =>set(state=>({...state, sidebarShow: value})),
}));
const useLoginModalShow = create(set => ({
    loginModalShow : false,
    setLoginModalShow : value => set(state=>({...state, loginModalShow: value}))
}))
const currentBetValue = create(set => ({
    currentValue: 100,
    setCurrentValue: value => set(state=>({...state, currentValue: value}))
}))
const useUserChips = create(set => ({
    userChips: [10,20,50,100,250,500],
    setUserChips: value => set(state=>({...state, userChips: value}))
}))
const useChips = create(set=>({
    allChips: [10,20,50,100,250,500,1000,5000],
    setAllChips: value => set(state=>({}))
}))
const useCurrentHall = create(set=>({
    currentHall: 0,
    setCurrentHall: value => set(state=>({...state, currentHall: value}))
}))
export {useSidebarStore, useLoginModalShow, currentBetValue,useChips, useUserChips, useCurrentHall}