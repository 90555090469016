import { CButton, CCol } from '@coreui/react'
import React, { useState } from 'react'
import Chips from './Chips'
import { useChips, useUserChips } from '../../../../../store'
import style from '../css/BetArea.module.css'
function ChipSettings({setShowSetting}) {
    const {allChips, setAllChips} = useChips()
    const {userChips, setUserChips} = useUserChips()
    const [currentChips, setCurrentChips]   =   useState(userChips)
    const pickChips = (value) => {
        if(currentChips.includes(value)){
            const index = currentChips.indexOf(value);
            if (index > -1) {
                currentChips.splice(index, 1);
            }
        } else {
            if(currentChips.length == 6){
                currentChips.shift()
                currentChips.push(value)
            } else 
            currentChips.push(value)
        }
        setCurrentChips(currentChips.sort((a,b)=>{
            return a-b
        }))
        setAllChips(allChips)
    }
    const saveSettings = () => {
        setUserChips(currentChips)
        setShowSetting(false)
    }
    return (
        <>
        {allChips.map((value,key)=>{
                return (<CCol className='px-2 mx-3' xs={2}  key={key} style={{height: '80px'}}>
                    <Chips value={value} state={currentChips.includes(value)==true?'betValueActive':''} onClick={()=>pickChips(value)} />
                </CCol>)
            })}
            <CButton color='success' onClick={()=>saveSettings()}>Save</CButton>
        </>
    ) 
}

export default ChipSettings
