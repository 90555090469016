import { CButton, CNav, CNavItem, CNavLink, CTabContent, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CTabPane } from '@coreui/react';
import React, { useState } from 'react'
import style from "../css/BetArea.module.css";
import BetArea from './BetArea';
import { useAuth } from '../../../../Auth/context/AuthContext';
import { useLoginModalShow } from '../../../../../store';
import CurrentBet from './CurrentBet';
import TodayReport from './TodayReport';
function BetAreaTabs({ hallId, fightId, fightNumber, rateWala, rateMeron }) {
    const [activeKey, setActiveKey] = useState(1);
    const { user } = useAuth()
    const { setLoginModalShow } = useLoginModalShow()
    return (
        <>
            <CNav
                variant="tabs"
                role="tablist"
            >
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 1}
                        onClick={() => setActiveKey(1)}
                        className={`${style.userReportPicker} ${activeKey === 1 ? style.userReportPickerActive : ""}`}
                    >
                        BET
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 2}
                        onClick={() => setActiveKey(2)}
                        className={`${style.userReportPicker} ${activeKey === 2 ? style.userReportPickerActive : ""}`}
                    >
                        Current Bets
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 3}
                        onClick={() => setActiveKey(3)}
                        className={`${style.userReportPicker} ${activeKey === 3 ? style.userReportPickerActive : ""
                            }`}
                    >
                        Today Report
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        href="#!"
                        active={activeKey === 4}
                        onClick={() => setActiveKey(4)}
                        className={`${style.userReportPicker} ${activeKey === 4 ? style.userReportPickerActive : ""
                            }`}
                    >
                        Law
                    </CNavLink>
                </CNavItem>
            </CNav>
            <CTabContent className='tab-bets'>
                { }
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    visible={activeKey === 1}
                    style={{ overflowY: "auto" }}
                >
                    {user!=null?<BetArea hallId={hallId} fightId={fightId} fightNumber={fightNumber} rateWala={rateWala} rateMeron={rateMeron} />:<CButton color='light' shape='rounded-pill' onClick={()=>setLoginModalShow(true)} style={{marginTop: '30%', width: '100%'}}>Login / Register to start betting</CButton>}
                </CTabPane>
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    visible={activeKey === 2}
                    style={{overflowY: "auto" }}
                >
                    <CurrentBet fightId={fightId} fightNumber={fightNumber} />
                </CTabPane>
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    visible={activeKey === 3}
                    style={{overflowY: 'scroll' }}
                >
                    <TodayReport />
                </CTabPane>
                <CTabPane
                    role="tabpanel"
                    aria-labelledby="law-tab"
                    visible={activeKey === 4}
                    style={{ backgroundColor: '#fff', overflowY: 'scroll', fontFamily:'math', maxHeight:'500px' }}
                    className="pt-3"
                >
                    <ol>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>WALA</strong>: Cricket with a “WHITE SPOT” on its body
                                (as shown in the image).
                            </p>
                        </li>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>MERON</strong>: Cricket without any spot on its body (as
                                shown in the image).
                            </p>
                        </li>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>“GHÉP DẾ (PAIRING)”</strong>: The technician pairing two
                                cricket..
                            </p>
                        </li>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>“ĐẶT CƯỢC (BETTING)”</strong>: The countdown starts at
                                00:03:00 when the BET notification appears.
                            </p>
                        </li>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>“THI ĐẤU (FIGHTING)”</strong>: The technician releases
                                two cricket, and the match is counted when two cricket bite each
                                other.
                            </p>
                        </li>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>Result “WIN”</strong>:
                            </p>
                            <ul>
                                <li>
                                    <p style={{ marginLeft: 0 }}>
                                        <strong>LOSE</strong>: If the cricket runs away and when the
                                        technician stimulate, it does NOT bite, crow (flap wings),
                                        or run.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ marginLeft: 0 }}>
                                        <strong>WIN</strong>: The remaining cricket wins, and when
                                        the technician stimulate, it continues to bite and crow
                                        (flap wings).
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>Result “CANCEL”</strong>:
                            </p>
                            <ul>
                                <li>
                                    <p style={{ marginLeft: 0 }}>
                                        <strong>“CANCEL”</strong>: When the arena opens for
                                        <strong>"FIGHTING", </strong>one of the two cricket competes,
                                        and the other cricket does NOT compete. The non-competing
                                        cricket does NOT bite, crow (flap wings), or run.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ marginLeft: 0 }}>
                                        <strong>“CANCEL”</strong>: When the arena opens for
                                        <strong>"FIGHTING"</strong>, both cricket do NOT compete.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p style={{ marginLeft: 0 }}>
                                <strong>Result “DRAW”</strong>:
                            </p>
                            <ul>
                                <li>
                                    <p style={{ marginLeft: 0 }}>
                                        When the arena opens for <strong>"FIGHTING"</strong>, both cricket
                                        have competed (with NO specified time limit).
                                    </p>
                                </li>
                                <li>
                                    <p style={{ marginLeft: 0 }}>
                                        Either both cricket run, and when, stimulate, both of them
                                        does NOT bite, crow (flap wings), or run.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ marginLeft: 0 }}>Or both cricket die.</p>
                                </li>
                            </ul>
                        </li>
                    </ol>
                </CTabPane>
            </CTabContent>
        </>
    )
}

export default BetAreaTabs
